<template>
  <div class="lilo-section">
    <BreadCrumb class="top-left" />

    <!-- Banner Header -->
    <div class="lilo-section__banner-header">
      <div class="lilo-section__banner-header__fold-corner-card">
        <div class="lilo-section__banner-header__fold-corner-card__titlefold">
          SEWA GUDANG DAN PICK UP BARANG
        </div>
        <div class="lilo-section__banner-header__fold-corner-card__descfold">
          LILO, PILIHAN ANDAL & TERPERCAYA UNTUK KEMBANGKAN BISNIS ANDA DENGAN MUDAH & AMAN
        </div>
      </div>
      <ImageLazy
        :src="imgSrcBannerHeader"
        alt="lilo_banner_header.png"
        class="do-flex"
        @error="(e) => setImageDefault(e, 'lilo-banner')"
      />
    </div>
    <!-- Banner Header -->

    <!-- Information List -->
    <listPoint
      :contents-list="listInformation"
      :title="'Mengapa Memilih <span style=\'color: #91252B!important;\';>LILO</span>?'"
    />
    <!-- Information List -->

    <!-- Banner Footer -->
    <div class="lilo-section__banner-footer">
      <div class="lilo-section__banner-footer__frame">
        <div class="coat-frame">
          <div class="lilo-section__banner-footer__frame__textsection">
            <div class="lilo-section__banner-footer__frame__textsection__wrapper">
              <div class="lilo-section__banner-footer__frame__textsection__wrapper__title">
                Kembangkan<br>Bisnis Anda Sekarang!
              </div>
              <div class="lilo-section__banner-footer__frame__textsection__wrapper__desc">
                Percayakan Penyimpanan Barang Anda kepada LILO
              </div>
              <div class="lilo-section__banner-footer__frame__textsection__wrapper__note">
                <span>Kantor Pusat LILO</span><br>
                JL. Kedoya Raya No. 55 RT.19/RW.07. Kedoya Utara. Kec. Kb. Jeruk Jakarta Barat, DKI Jakarta. 11520
              </div>
            </div>
          </div>
          <div class="lilo-section__banner-footer__frame__formsection">
            <div class="lilo-section__banner-footer__frame__formsection__wrapper">
              <div class="coat">
                <div class="item">
                  <Input
                    v-model="lilo.pic_name"
                    name="fullname"
                    label="Nama Lengkap *"
                    placeholder="Masukkan nama lengkap"
                    tabindex="0"
                    class="lilo-section__banner-footer__frame__formsection__wrapper__input"
                    :is-error="pic_nameError"
                    :error-message="pic_nameErrorMsg"
                  />
                </div>
                <div class="item">
                  <Input
                    v-model="lilo.email"
                    name="email"
                    label="Email *"
                    placeholder="Masukkan email Anda"
                    tabindex="0"
                    class="lilo-section__banner-footer__frame__formsection__wrapper__input"
                    :is-error="emailError"
                    :error-message="emailErrorMsg"
                  />
                </div>
                <div class="item">
                  <Input
                    v-model="lilo.phone_number"
                    name="phone"
                    label="No. Handphone *"
                    placeholder="diawali angka 8"
                    prefix="+62"
                    tabindex="0"
                    class="lilo-section__banner-footer__frame__formsection__wrapper__input"
                    :is-error="phone_numberError"
                    :error-message="phone_numberErrorMsg"
                  />
                </div>
                <div class="item">
                  <Input
                    v-model="lilo.company_name"
                    name="company_name"
                    label="Nama Usaha/Merek *"
                    tabindex="0"
                    placeholder="Masukkan nama usaha/merek"
                    class="lilo-section__banner-footer__frame__formsection__wrapper__input"
                    :is-error="company_nameError"
                    :error-message="company_nameErrorMsg"
                  />
                </div>
              </div>
              <div class="coat2">
                <Textarea
                  v-model="lilo.question"
                  label="Pesan/Pertanyaan *"
                  placeholder="Masukkan pesan atau pertanyaan"
                  class="lilo-section__banner-footer__frame__formsection__wrapper__input__area"
                  :is-error="questionError"
                  :error-message="questionErrorMsg"
                />
                <Button
                  text="Kirim"
                  type-class="yellowgold"
                  class="lilo-section__banner-footer__frame__formsection__wrapper__button"
                  @click="submit()"
                />
                <div
                  v-show="msg"
                  class="msg"
                >
                  <img
                    :src="icoMsg"
                    alt="msg.png"
                  >
                  <!-- eslint-disable vue/no-v-html -->
                  <span v-html="textMsg" />
                  <!-- eslint-enable -->
                </div>
                <div class="lilo-section__banner-footer__frame__textsection__wrapper__note2">
                  <span>Kantor Pusat LILO</span><br>
                  JL. Kedoya Raya No. 55 RT.19/RW.07. Kedoya Utara. Kec. Kb. Jeruk Jakarta Barat, DKI Jakarta. 11520
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Banner Footer -->
  </div>
</template>

<script>
import axios from 'axios';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb';
import Button from '../../components/new-button/Button';
import mixinMobile from '../../misc/mixinMobile';
import mixinMetaInfo from '../../misc/mixinMetaInfo';
import listPoint from '@/components/new-profit-and-registration/ProfitAndRegistrationContent';
import LIST_CONTENT from './app/constants/list';
import Input from '@/components/new-input/Input';
import Textarea from '@/components/new-textarea/Textarea';
import { setImageDefault } from '@/components/ImageLazy/util';
import ImageLazy from '@/components/ImageLazy/index.vue';
import { mapActions } from 'vuex';

export default {
  name: 'LILO',
  components: {
    BreadCrumb,
    Button,
    listPoint,
    Input,
    Textarea,
    ImageLazy,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  data() {
    return {
      searchMetaInfo: '_lilo', // from mixinMetaInfo, path tapi / dengan _ ex: /about jadi _about
      LIST_CONTENT,
      lilo: {
        pic_name: '',
        email: '',
        phone_number: '',
        company_name: '',
        question: '',
        category: 'LILO',
      },
      pic_nameError: false,
      pic_nameErrorMsg: '',
      emailError: false,
      emailErrorMsg: '',
      phone_numberError: false,
      phone_numberErrorMsg: '',
      company_nameError: false,
      company_nameErrorMsg: '',
      questionError: false,
      questionErrorMsg: '',
      msg: false,
      textMsg: '',
      icoMsg: '',
      icoFailed: '/images/ics_f_close_circle.svg',
      icoSuccess: '/images/ics_f_check_circle.svg',
      timeout: 5000,
      setImageDefault,
    };
  },
  computed: {
    listInformation() {
      const mobile = LIST_CONTENT.INFORMATION;
      const desktop = [...LIST_CONTENT.INFORMATION];
      let final = null;
      if (this.windowWidth <= 599) {
        final = mobile;
      } else {
        desktop.sort((a, b) => a.sort - b.sort);
        final = desktop;
      }
      return final;
    },
    imgSrcBannerHeader() {
      return this.windowWidth <= 599
        ? '/images/lilo/bg_lilo_header_mobile.webp'
        : '/images/lilo/bg_lilo_header.webp';
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions(['setBusinessInquiryId']),
    _checkPicName() {
      if (!this.lilo.pic_name) {
        this.pic_nameError = true;
        this.pic_nameErrorMsg = 'Nama lengkap wajib diisi';
      } else {
        this.pic_nameError = false;
      }
    },
    _checkEmail() {
      if (!this.lilo.email) {
        this.emailError = true;
        this.emailErrorMsg = 'Email wajib diisi';
      } else if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(this.lilo.email)) {
        this.emailError = false;
      } else {
        this.emailError = true;
        this.emailErrorMsg = 'Format yang anda masukan salah';
      }
    },
    _checkPhoneNumber() {
      if (!this.lilo.phone_number) {
        this.phone_numberError = true;
        this.phone_numberErrorMsg = 'No. handphone wajib diisi';
      } else if (this.lilo.phone_number.charAt(0) !== '8') {
        this.phone_numberError = true;
        this.phone_numberErrorMsg = 'Format yang anda masukan salah';
      } else if (this.lilo.phone_number.length < 9 || this.lilo.phone_number.length > 12) {
        // inc prefix +62 (total min 12 char - max 15 char)
        this.phone_numberError = true;
        this.phone_numberErrorMsg = 'Panjang karakter antara 9-12 angka';
      } else {
        this.phone_numberError = false;
      }
    },
    _checkCompanyName() {
      if (!this.lilo.company_name) {
        this.company_nameError = true;
        this.company_nameErrorMsg = 'Nama usaha/merek wajib diisi';
      } else {
        this.company_nameError = false;
      }
    },
    _checkQuestion() {
      if (!this.lilo.question) {
        this.questionError = true;
        this.questionErrorMsg = 'Pesan/pertanyaan wajib diisi';
      } else {
        this.questionError = false;
      }
    },
    check() {
      // name
      this._checkPicName();
      // email
      this._checkEmail();
      // phone
      this._checkPhoneNumber();
      // company_name
      this._checkCompanyName();
      // question
      this._checkQuestion();

      if (
        !this.pic_nameError
        && !this.emailError
        && !this.phone_numberError
        && !this.company_nameError
        && !this.questionError
      ) {
        return true;
      }
      this.textMsg = '';
      this.icoMsg = '';
      this.msg = false;
      return false;
    },
    submit() {
      if (this.check()) {
        const api = process.env.VUE_APP_DOMAIN_URL;
        const lilo = {
          pic_name: this.lilo.pic_name,
          email: this.lilo.email,
          phone_number: `+62${this.lilo.phone_number}`,
          company_name: this.lilo.company_name,
          question: this.lilo.question,
          category: this.lilo.category,
        };
        axios
          .post(`${api}/v1/business`, lilo, {
            headers: {
              Authorization: process.env.VUE_APP_TOKEN_URL,
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            if (res.status === 200) {
              this.msg = true;
              this.textMsg = 'Berhasil dikirim';
              this.icoMsg = this.icoSuccess;
              this.lilo.pic_name = '';
              this.lilo.email = '';
              this.lilo.phone_number = '';
              this.lilo.company_name = '';
              this.lilo.question = '';
              setTimeout(this.updateMsg, this.timeout);
              if (res.data && res.data?.business_inquiry_id) {
                this.$router.push(`/lilo/success?q=${res.data?.business_inquiry_id}`)
                this.$store.dispatch('setBusinessInquiryId', res.data?.business_inquiry_id)
              }
            }
          })
          .catch((err) => {
            const errMsg = (err.response.data.message);
            this.msg = true;
            if (errMsg) {
              this.textMsg = `Gagal dikirim, ${errMsg.id}`;
            } else {
              this.textMsg = 'Gagal dikirim, coba beberapa saat lagi';
            }
            this.icoMsg = this.icoFailed;
          });
      }
    },
    updateMsg() {
      if (this.icoMsg === this.icoSuccess) this.msg = false;
    },
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
