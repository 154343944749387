/** @type {Array<InformationInfo>} */
const INFORMATION = [
  {
    title: 'Sewa Gudang & Handling',
    desc: 'Tersedia tempat penyimpanan barang dengan standar keamanan tinggi serta ditangani khusus oleh tim LILO.',
    img: 'lilo/ics_o_money_alt.svg',
    icoBgColor: '#91252B',
    sort: 1,
  },
  {
    title: 'Gratis Pick Up Barang',
    desc: 'Tersedia layanan gratis Pick Up untuk barang yang akan Anda simpan di gudang LILO.',
    img: 'lilo/ics_o_nearby_shuttle.svg',
    icoBgColor: '#91252B',
    sort: 3,
  },
  {
    title: 'COD <i>Realtime Cash Withdrawal*</i>',
    desc: 'Nikmati kemudahan dalam penarikan transaksi COD secara langsung tanpa harus menunggu lama.',
    img: 'lilo/ics_o_coinCOD.svg',
    icoBgColor: '#91252B',
    sort: 5,
  },
  {
    title: 'Pengiriman Domestik & Internasional',
    desc: 'Selain pengiriman Domestik, LILO juga melayani pengiriman barang ke beberapa negara.',
    img: 'lilo/ics_o_plane.svg',
    icoBgColor: '#91252B',
    sort: 7,
  },
  {
    title: 'Customer Service & Admin 24 Jam',
    desc: 'Menyediakan CS & Admin Marketplace untuk membantu operasional toko Anda.',
    img: 'lilo/ics_o_profile.svg',
    icoBgColor: '#91252B',
    sort: 2,
  },
  {
    title: 'Marketing Support',
    desc: 'Mengatur strategi marketing & partnership untuk meningkatkan penjualan & performa toko Anda.',
    img: 'lilo/ics_o_thumb-up.svg',
    icoBgColor: '#91252B',
    sort: 4,
  },
  {
    title: 'Monitor Pengiriman & Stok Barang Online',
    desc: 'Lihat langsung pengiriman dan stok barang dengan sistem yang terintegrasi dengan platform WMS & OMS.',
    img: 'lilo/ics_o_search.svg',
    icoBgColor: '#91252B',
    sort: 6,
  },
  {
    title: 'Dijamin Dengan Asuransi',
    desc: 'Asuransi menjamin penyimpanan dan pengiriman barang Anda tetap aman dan terjaga.',
    img: 'lilo/ics_o_shield check.svg',
    icoBgColor: '#91252B',
    sort: 8,
  },
];

module.exports = { INFORMATION };
